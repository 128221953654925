<template>

</template>

<script>
var that
import Vue from "vue";
import http from '@/utils/httpRequest'
import {twoDomainLogin} from '@/utils/common'
import { Toast } from 'vant';
export default {
  data(){
    return{}
  },
  created(){
    that = this
    console.log('参数',this.$route.query);
    for (var i in this.$route.query) {
      sessionStorage.setItem(i, localStorage[i])
    }
    if (that.$route.query.title) {
      document.title = that.$route.query.title
      Vue.prototype.$globalData.appName = that.$route.query.title
    }
    Vue.prototype.$globalData.moduleId = that.$route.query.moduleId
    let token = that.$route.query.token
    Vue.prototype.$globalData.token = token
    let topToken = that.$route.query.topToken
    Vue.prototype.$globalData.topToken = topToken
    let orgId = that.$route.query.orgId
    Vue.prototype.$orgId = orgId
    Vue.prototype.$globalData.userInfo.orgId = orgId
    let userId = that.$route.query.userId
    Vue.prototype.$globalData.userInfo.userId = userId
    let street = that.$route.query.street
    Vue.prototype.$globalData.userInfo.street = street
    let county = that.$route.query.county
    Vue.prototype.$globalData.userInfo.county = county
    let communityName = that.$route.query.communityName
    Vue.prototype.$globalData.userInfo.communityName = communityName
    let streetName = that.$route.query.streetName
    Vue.prototype.$globalData.userInfo.streetName = streetName
    let userName = that.$route.query.userName
    Vue.prototype.$globalData.userInfo.userName = userName
    let mobile = that.$route.query.mobile
    Vue.prototype.$globalData.userInfo.mobile = mobile
    let address = that.$route.query.address
    Vue.prototype.$globalData.address = address
    Vue.prototype.$globalData.searchValue = that.$route.query.searchValue
    Vue.prototype.$globalData.searchType = that.$route.query.searchType
    Vue.prototype.$globalData.type = that.$route.query.type
    Vue.prototype.$globalData.name = that.$route.query.name
    Vue.prototype.$globalData.sex = that.$route.query.sex
    Vue.prototype.$globalData.userInfo.codeType = parseInt(that.$route.query.codeType)
    let domain = that.$route.query.domain
    Vue.prototype.$encrypt = that.$route.query.encrypt
    Vue.prototype.$isOld = parseInt(that.$route.query.isOld)
    if (street == orgId) {
      Vue.prototype.$globalData.userInfo.depth = 4
    } else {
      Vue.prototype.$globalData.userInfo.depth = 5
    }
    if (domain) {
      console.log(domain);
      Vue.prototype.$globalData.domain = decodeURIComponent(domain) + '/api'
      Vue.prototype.$globalData.domain2 = decodeURIComponent(domain)
      Vue.prototype.$http.changeUrl(decodeURIComponent(domain),true)
      Vue.prototype.$httpApp.changeUrl(decodeURIComponent(domain),true)

    }
    let appid = that.$route.query.appid
    Vue.prototype.$globalData.appid = appid
    Vue.prototype.$url = window.location.href.split('sso')[0]
    console.log(this.$url)
    if (that.$route.query.url == 'street-home') Vue.prototype.$depth = 4

    let code = that.$route.query.appCode
    if (that.$route.query.ids) {
      if (that.$route.query.url == 'userRes-info') {
        this.$router.push({path: that.$route.query.url, query:{userId: that.$route.query.ids}})
      } else {
        this.$router.push({path: that.$route.query.url, query:{ids: that.$route.query.ids}})
      }
    } else if (that.$route.query.isNoBasicsCheck) {
      this.$router.push({path: that.$route.query.url, query:{isNoBasicsCheck: that.$route.query.isNoBasicsCheck}})
    } else if ((code == 'county_form' || code == 'uht_county_form')){
      that.getCountyFormCode(that.$route.query.appUrl, that.$route.query.appName, that.$route.query.appId, code)
    } else  {
      this.$router.push(that.$route.query.url)
    }
    if (that.$route.query.result) {
      Vue.prototype.$globalData.result = that.$route.query.result
    }
    if (that.$route.query.notInspect) {
      Vue.prototype.$globalData.notInspect = that.$route.query.notInspect
    }
    if (that.$route.query.warningType) {
      Vue.prototype.$globalData.warningType = that.$route.query.warningType
    }
    console.log(window.location)

    // let url = window.location.href.split('sso')[0] + that.$route.query.url
    if (that.$route.query.url == 'appSSO' && that.$route.query.appDomain && code !== 'county_form' && code !== 'uht_county_form') {
      let url = decodeURIComponent(that.$route.query.appUrl)
      let appId = that.$route.query.appId
      let appCode = that.$route.query.appCode
      let appName = that.$route.query.appName
      this.$router.push({path: that.$route.query.url, query: {url, appId, appCode, appName, peopleType: 0}})
    }
    if (that.$route.query.remindCode) {
      Vue.prototype.$globalData.remindCode = that.$route.query.remindCode
    }

    //监听微信小程序的返回按钮
    window.addEventListener('popstate', (event) => {
      //当不存在历史页面时，退出webview
      if (!event.state) {
        wx.miniProgram.navigateBack();
      }
    });
  },
  methods: {
    getCountyFormCode (url, name, appId, code) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/user/getCountyFormCode'),
        method: 'post'
      }).then(({data}) => {
        if (data) {
          let countyFormCode = data.code
          // this.$nextTick(() => {
          if (data.code){
            this.$router.push({path: '/appSSO', query: {url, appId, appCode:code, appName:name, countyFormCode}})
          }

          // })
        }
      })
    },
  }
}

</script>

<style scoped>

</style>

